import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { seo, intro, main } from '../content/company'
import Layout from '../components/Layout'
import Intro from '../components/Intro'
import { useLangContext } from '../context/lang.context'
import Seo from '../components/Seo'
import Main from '../components/Main'
import Tiles from '../components/Tiles'
import { pageName } from '../content/_dictonary'

const Company = () => {
  const { lang } = useLangContext()

  const query = useStaticQuery(graphql`
    {
      image: file(absolutePath: { regex: "/images/intros/contact.jpg/" }) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            quality: 100
            placeholder: DOMINANT_COLOR
            formats: [JPG]
          )
        }
      }
      imageAbout: file(absolutePath: { regex: "/images/intros/about.jpg/" }) {
        childImageSharp {
          gatsbyImageData(width: 480, quality: 100, formats: [JPG])
        }
      }
      imageCollaboration: file(
        absolutePath: { regex: "/images/intros/collaboration.jpg/" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 480, quality: 100, formats: [JPG])
        }
      }
      imageCSR: file(absolutePath: { regex: "/images/intros/csr.jpg/" }) {
        childImageSharp {
          gatsbyImageData(width: 480, quality: 100, formats: [JPG])
        }
      }
      imageCareer: file(absolutePath: { regex: "/images/intros/career.jpg/" }) {
        childImageSharp {
          gatsbyImageData(width: 480, quality: 100, formats: [JPG])
        }
      }
      imageJobs: file(absolutePath: { regex: "/images/intros/jobs.jpg/" }) {
        childImageSharp {
          gatsbyImageData(width: 480, quality: 100, formats: [JPG])
        }
      }
      imagePolicy: file(absolutePath: { regex: "/images/intros/policy.jpg/" }) {
        childImageSharp {
          gatsbyImageData(width: 480, quality: 100, formats: [JPG])
        }
      }
      imageNews: file(absolutePath: { regex: "/images/intros/blog.jpg/" }) {
        childImageSharp {
          gatsbyImageData(width: 480, quality: 100, formats: [JPG])
        }
      }
      imageWiedza: file(
        absolutePath: { regex: "/images/intros/knowledge.jpg/" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 480, quality: 100, formats: [JPG])
        }
      }
      imageCarrier: file(
        absolutePath: { regex: "/images/intros/carrier.jpg/" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 480, quality: 100, formats: [JPG])
        }
      }
      imagePresentation: file(
        absolutePath: { regex: "/images/intros/presentation.jpg/" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 480, quality: 100, formats: [JPG])
        }
      }
    }
  `)

  const links = [
    {
      slug: 'about',
      name: pageName.about[lang],
      image: query.imageAbout,
    },
    {
      slug: 'collaboration',
      name: pageName.collaboration[lang],
      image: query.imageCollaboration,
    },
    {
      slug: 'csr',
      name: pageName.csr[lang],
      image: query.imageCSR,
    },
    {
      slug: 'kariera',
      name: pageName.career[lang],
      image: query.imageCareer,
    },
    {
      slug: 'jobs',
      name: pageName.jobs[lang],
      image: query.imageJobs,
    },
    // {
    //   slug: 'blog?category=news',
    //   name: lang === 'en' ? 'News' : 'Wydarzenia',
    //   image: query.imageNews,
    // },
    // {
    //   slug: 'blog?category=wiedza',
    //   name: lang === 'en' ? 'Knowledge' : 'Wiedza',
    //   image: query.imageWiedza,
    // },
    {
      slug: 'carrier',
      name: pageName.carrier[lang],
      image: query.imageCarrier,
    },
    {
      slug: 'policy',
      name: pageName.policy[lang],
      image: query.imagePolicy,
    },
    {
      slug: 'presentation-omida-vls',
      name: pageName.presentation[lang],
      image: query.imagePresentation,
    },
  ]

  return (
    <Layout>
      <Seo
        title={seo.title[lang]}
        desc={seo.desc[lang]}
        imageUrl={query.image.publicURL}
        url={seo.url}
      />
      <Intro data={intro} image={query.image.childImageSharp} h={2} />
      <Main data={main} h={1} />
      <Tiles data={links} />
    </Layout>
  )
}

export default Company
