export const seo = {
  url: 'company',
  title: {
    en: 'Transport Company | Career | Cooperation',
    es: 'Empresa de transporte | Carrera | Cooperación',
    ro: 'Companie de transport | Cariera | Cooperare',
  },
  desc: {
    en: `Welcome to Omida VLS - the most recognizable Polish brand of TFL. Find out more about the company's news, our activities and careers in the TFL industry.`,
    es: `Bienvenido a Omida VLS, la marca polaca más reconocible de TFL. Obtenga más información sobre las noticias de la compañía, nuestras actividades y carreras en la industria de TFL.`,
    ro: `Bine ați venit la Omida VLS - cel mai cunoscut brand polonez al TFL. Aflați mai multe despre noutățile companiei, activitățile și carierele noastre în industria TFL.`,
  },
  keywords: ['omida', 'logistics', 'transport'],
}

export const intro = {
  title: {
    en: 'Company',
    es: 'Compañía',
    ro: 'Companie',
  },
  desc: {
    en: `Welcome to Omida VLS - the most recognizable Polish brand of TFL. Find out more about the company's news, our activities and careers in the TFL industry.`,
    es: `Bienvenido a Omida VLS, la marca polaca más reconocible de TFL. Obtenga más información sobre las noticias de la compañía, nuestras actividades y carreras en la industria de TFL.`,
    ro: `Bine ați venit la Omida VLS - cel mai cunoscut brand polonez al TFL. Aflați mai multe despre noutățile companiei, activitățile și carierele noastre în industria TFL.`,
  },
  button: {
    text: {
      en: 'Learn more',
      es: 'Aprende más',
      ro: 'Află mai multe',
    },
    action: 'SCROLL',
  },
}

export const main = {
  body: [
    {
      headline: {
        en: 'Polish Transport, Forwarding and Logistics Company',
        es: 'Empresa Polaca de Transporte, Forwarding y Logística',
        ro: 'Companie poloneză de transport, expediere și logistică',
      },
      texts: [
        {
          en: '<div>Omida VLS is a Polish transport company. We deal with the transport of goods by <a href="/road-transport/"> road</a>, <a href="/rail-transport/">rail</a> and <a href="/intermodal/">intermodal</a> transport. As part of our services, we organize <a href="/ftl-transport/">FTL</a> and <a href="/ltl-transport/">LTL</a> transport.</div>',
          es: '<div>Omida VLS es una empresa de transporte polaca. Nos ocupamos del transporte de mercancías por <a href="/road-transport/">carretera</a>, <a href="/rail-transport/">ferrocarril</a> y transporte <a href="/intermodal/">intermodal</a>. Como parte de nuestros servicios, organizamos transporte <a href="/ftl-transport/">FTL</a> y <a href="/ltl-transport/">LTL</a>.</div>',
          ro: '<div>Omida VLS este o companie de transport poloneză. Ne ocupăm de transportul de mărfuri pe <a href="/road-transport/">rutier</a>, <a href="/rail-transport/">feroviar</a> și <a href="/ transport intermodal/">intermodal</a>. Ca parte a serviciilor noastre, organizăm transport <a href="/ftl-transport/">FTL</a> și <a href="/ltl-transport/">LTL</a>.</div>',
        },
      ],
    },
    {
      headline: {
        en: 'Polish Transport Company',
        es: 'Compañía de transporte polaca',
        ro: 'Compania poloneză de transport',
      },
      texts: [
        {
          en: '<div>Our branches are located in <a href="/contact/#offices">20 cities all over Poland</a>. From 2019, we have a <a href="/contract-logistics/">class A warehouse near Warsaw</a>, handling all logistic processes of our clients. Our strength is <a href="/global/">full truckload transport all over Europe</a>. This is what we are the best at.</div>',
          es: '<div>Nuestras sucursales están ubicadas en <a href="/contact/#offices">20 ciudades de toda Polonia</a>. Desde 2019, contamos con un almacén de <a href="/contract-logistics/">clase A cerca de Varsovia</a>, manejando todos los procesos logísticos de nuestros clientes. Nuestro punto fuerte es el <a href="/global/">transporte de carga completa FTL por toda Europa</a>. Esto es en lo que somos los mejores.</div>',
          ro: '<div>Sucursalele noastre sunt situate în <a href="/contact/#offices">20 de orașe din toată Polonia</a>. Din 2019, avem un <a href="/contract-logistics/">depozit clasa A lângă Varșovia</a>, care se ocupă de toate procesele logistice ale clienților noștri. Puterea noastră este <a href="/global/">transportul complet cu camioane în toată Europa</a>. La asta suntem cei mai buni.</div>',
          },
        {
          en: `<div>Are you looking to transport goods in Europe? Choose Omida VLS. We focus on constant contact between the supervisor and the client and the team's specialization in servicing a given industry.</div>`,
          es: '<div>¿Está buscando para el transporte de mercancías en Europa? Elija Logística Omida. Nos enfocamos en el contacto constante entre el supervisor y el cliente y la especialización del equipo en el servicio a una industria determinada.</div>',
          ro: '<div>Doriți să transportați mărfuri în Europa? Alegeți Omida VLS. Ne concentrăm pe contactul constant între supervizor și client și pe specializarea echipei în deservirea unei anumite industrii.</div>',
        },
      ],
    },
  ],
}
